import React from "react";
import { useTranslation } from "react-i18next";


function Infobiliardi() {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('info_bil_1')}</h2>
      <p>
        {t('info_bil_2')}
      </p>
      <p>
        {t('info_bil_3')}
      </p>
      <p>
        {t('info_bil_4')}
      </p>
      <p>
        {t('info_bil_5')}
      </p>
      <p>
        {t('info_bil_6')}
      </p>
      <p>
        {t('info_bil_7')}
      </p>
      <h2>  {t('info_bil_8')}</h2>
      <p>
        {t('info_bil_9')}
      </p>
      <h2>
        {t('info_bil_10')}
      </h2>
      <p>
        {t('info_bil_11')}
      </p>
      <h2>
        {t('info_bil_12')}
      </h2>
      <p>
        {t('info_bil_13')}
      </p>
    </div>
  );
}

export default Infobiliardi;
