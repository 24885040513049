import React from "react";
import Contacts from "../../Components/Molecules/Contacts/Contacts";

function Contatti() {
  return (
    <div>
      <div className="mt-7 py-5 bg-platinum hidden">
        <div className="ml-14">
          <a href="/">
            <b>SARDI G. & D. snc</b>
          </a>
          <br />
          <a href="/dovesiamo">Deviazione Statale 10, n. 57 </a> <br />
          <a href="/contatti/dovesiamo">15122 Spinetta Marengo (AL)</a> <br />
          <a href="/contatti">Tel. +39 0131 618397 </a> <br />
          <a href="/contatti">Fax: +39 0131 617249 </a> <br />
          <a href="/contatti">Email: info@sarditable.it </a> <br />
          <a href="/contatti">P.Iva 00162720064</a> <br />
        </div>
      </div>
      <Contacts></Contacts>
    </div>
  );
}

export default Contatti;
