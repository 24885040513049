import React from "react";
import Prodotti from "../../Components/Molecules/Prodotti/Prodotti";
import { useTranslation } from "react-i18next";


function Calciobalilla() {
  const { t } = useTranslation();

  return (
    <div>
      <Prodotti slug="calciobalilla" title={t('_calcio_balilla')}/>
    </div>
  );
}

export default Calciobalilla;
