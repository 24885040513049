import React from "react";
import "./Loader.css";

function Loader() {
	return (
		<div>
			<div class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default Loader;
