import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Foto, Prodotti } from "../../Assets/Pictures/Home";
import "./Home.css";
import Prodotto from "../../Components/Atoms/Card/Card";
import CTAsec from "../../Components/Atoms/CTAsec/CTAsec";
import { products } from "../../Resources/products";
import Schedaprodotto2 from "../../Components/Molecules/SchedaProdotto/Schedaprodotto2";
import { useTranslation } from "react-i18next";
import FAQ from "../../Components/Molecules/FAQ/FAQ";

function Home() {
	const { t } = useTranslation();
	var settings = {
		dots: true,
		infinite: true,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 4500,
		arrows: false,
		pauseOnHover: true,
	};

	var lista = [];

	var handleRandom = (type, n) => {
		if (n % 2 !== 0) {
			var random = Math.floor(Math.random() * type.length);
			if (lista[n - 1] === type[random]) {
				var random = Math.floor(Math.random() * type.length);
				return random;
			}
			return random;
		} else {
			return Math.floor(Math.random() * type.length);
		}
	};

	lista = [...lista, products.Biliardi[handleRandom(products.Biliardi, 0)]];
	lista = [...lista, products.Biliardi[handleRandom(products.Biliardi, 1)]];
	lista = [
		...lista,
		products.Calciobalilla[handleRandom(products.Calciobalilla, 2)],
	];
	lista = [
		...lista,
		products.Calciobalilla[handleRandom(products.Calciobalilla, 3)],
	];
	lista = [...lista, products.Altrigiochi[1]];
	lista = [...lista, products.Altrigiochi[0]];

	console.log(lista);

	return (
		<div className=" mt-10 md:px-24">
			<div className="flex justify-center">
				<Slider
					{...settings}
					className={`slider flex justify-center w-[100%] py-16`}
				>
					<Schedaprodotto2 prodotto={lista[0]} slug={"biliardi"} />
					<Schedaprodotto2 prodotto={lista[2]} slug={"calciobalilla"} />
					<Schedaprodotto2 prodotto={lista[1]} slug={"biliardi"} />
					<Schedaprodotto2 prodotto={lista[3]} slug={"calciobalilla"} />
				</Slider>
			</div>
			<CTAsec titolo={t("who_are_we")} link={"/azienda/storia"}>
				<p>{t("chi_siamo_txt")}</p>
			</CTAsec>
			<section className="p-6 mt-12">
				<div className="text-center">
					<h1 className="text-3xl uppercase font-semibold">{t("_prodotti")}</h1>
				</div>
				<div className="p-10 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4">
					<Prodotto
						foto={Prodotti.P1}
						nome={t("_calcio_balilla")}
						slug="calciobalilla"
					/>
					<Prodotto foto={Prodotti.P2} nome={t("_biliardi")} slug="biliardi" />
					<Prodotto
						foto={Prodotti.P3}
						nome={t("tavoli_ping_pong")}
						slug="altrigiochi"
					/>
					<Prodotto foto={Prodotti.P4} nome="Shuffleboard" slug="altrigiochi" />
				</div>
				<CTAsec link={"/personalizzazioni"}>
					<p>{t("_pers_desc")}</p>
				</CTAsec>
			</section>
		</div>
	);
}

export default Home;
