import React from "react";
import { Link } from "react-router-dom";
import Omino from "../../../Assets/Pictures/omino.png";
import Logo from "../../../Assets/Pictures/logon.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Navbar } from "flowbite-react";
import { sardi } from "../../../Custom";

function NavbarCopy() {
	const { t } = useTranslation();
	const items = [
		{
			nome: "_azienda",
			id: "azienda",
			link: "",
			haschild: true,
			child: [
				{ nome: "_storia", link: "/azienda/storia" },
				{ nome: "_qualità", link: "/azienda/qualita" },
				{ nome: "_servizio", link: "/azienda/servizio" },
				{ nome: "Blog", link: "/blog" },
				{ nome: "FAQ", link: "/FAQ" },
			],
		},
		{
			nome: "_calcio_balilla",
			id: "balilla",
			link: "/calciobalilla",
			haschild: false,
		},
		{
			nome: "_biliardi",
			id: "biliardi",
			link: "/biliardi",
			haschild: false,
		},
		{
			nome: "_altrigiochi",
			id: "altro",
			link: "/altrigiochi",
			haschild: false,
		},
		{
			nome: "_personalizzazioni",
			id: "personalizzazioni",
			link: "/personalizzazioni",
			haschild: false,
		},
		{
			nome: "_contatti",
			id: "contatti",
			link: "/contatti",
			haschild: true,
			child: [
				{ nome: "_contatti", link: "/contatti" },
				{ nome: "dove_siamo", link: "/contatti/dovesiamo" },
			],
		},
	];

	const clas = "fixed rounded-3xl text-center shadow-lg h-[100%]";
	//https://tailwindcomponents.com/component/responsive-navbar-with-dropdown

	return (
		<Navbar
			theme={{sardi}}
			fluid={true}
			className="fixed w-[100vw] z-50 border-b border-solid border-quicksilver shadow-md"

		>
			<Navbar.Brand href="/">
				<img
					src={Logo}
					alt="Sardi"
					className=""
					width="150px"
				></img>

			</Navbar.Brand>
			<div className="flex md:order-2">
				<div className="flex space-x-3 items-center justify-center">
					<p
						onClick={() => {
							i18next.changeLanguage("it");
						}}
						className="cursor-pointer ml-3 px-1 font-semibold bg-transparent rounded-3xl md:mt-0   focus:outline-none focus:shadow-outline"
					>
						<img className="w-6" src={require('../../../Assets/Pictures/FlagIT.png')} alt="" />
					</p>
					<p
						onClick={() => {
							i18next.changeLanguage("en");
						}}
						className="cursor-pointer px-1 font-semibold bg-transparent rounded-3xl md:mt-0   focus:outline-none focus:shadow-outline"
					>
						<img className="w-7" src={require('../../../Assets/Pictures/FlagUK.png')} alt="" />
					</p>
				</div>
				<Navbar.Toggle />
			</div>
			<Navbar.Collapse>
				<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0">
					<Dropdown className="text-center  "
						label={t("_azienda")}
						inline={true}
					>
						<Dropdown.Item>
							<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/azienda/storia">
								{t("_storia")}
							</Navbar.Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/azienda/qualita">
								{t("_qualità")}
							</Navbar.Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/azienda/servizio">
								{t("_servizio")}
							</Navbar.Link>
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item>
							<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/blog">
								{t("Blog")}
							</Navbar.Link>
						</Dropdown.Item>
						<Dropdown.Item  onClick={() => window.location.href = '/faq'}>
							<Navbar.Link className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/FAQ">
								FAQ
							</Navbar.Link>
						</Dropdown.Item>

					</Dropdown>
				</Navbar.Link>
				<Navbar.Link className=" font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0" href="/calciobalilla">
					{t("_calcio_balilla")}
				</Navbar.Link>
				<Navbar.Link className=" font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0 " href="/biliardi">
					{t("_biliardi")}
				</Navbar.Link>
				<Navbar.Link className=" font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0 " href="/altrigiochi">
					{t("_altrigiochi")}
				</Navbar.Link>
				<Navbar.Link className=" font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0 " href="/personalizzazioni">
					{t("_personalizzazioni")}
				</Navbar.Link>
				<Navbar.Link className=" font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0">
					<Dropdown className="text-center "
						label={t("_contatti")}
						inline={true}
					>
						<Dropdown.Item>
							<Navbar.Link
								className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0"
								href="/contatti">
								{t("_contatti")}
							</Navbar.Link>
						</Dropdown.Item>
						<Dropdown.Item>
							<Navbar.Link
								className="font-semibold hover:font-bold block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:text-[#000] md:p-0"
								href="/contatti/dovesiamo">
								{t("dove_siamo")}
							</Navbar.Link>
						</Dropdown.Item>
					</Dropdown>
				</Navbar.Link>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default NavbarCopy;
