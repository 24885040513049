import React, { useState } from "react";
import CTAbtn from "../../Atoms/CTAbtn/CTAbtn";
import { useTranslation } from "react-i18next";

function Schedaprodotto({ prodotto, slug }) {

  const {t} = useTranslation()
  const [immagine, setImmagine] = useState(prodotto.img[0]);





  return (
    <div className="grid grid-cols-1 place-content-between">
      <a href={`/${slug}/${prodotto.slug}`}>
      <img

        className=" ml-[50%] translate-x-[-50%] px-20 transition-all duration-500"
        src={require(`../../../Assets/Pictures/${slug}/${immagine}`)}
        alt=""
        height={"100%"}
      />
      </a>
      <div className="p-6">
        <h1 className="text-center font-semibold text-lg">{t(prodotto.title)}</h1>
        <p className="text-center font-semibold">{t(prodotto.place)}</p>
        <p className="text-center">{t(prodotto.descr)}</p>
      </div>
      <div className="">

      <CTAbtn link={`${slug}/${prodotto.slug}`} title={t("_scopri")}></CTAbtn>
      </div>
    </div>
  );
}

export default Schedaprodotto;
