


export const sardi = {
    navbar: {
        root: {
            hover: 'text-[#000]',
            base: 'bg-[#000]'

        }

    }
}

