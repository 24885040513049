import React from "react";

function Card({ nome, foto, slug }) {
	return (
		<div onClick={()=>{window.location.href="/"+slug}} className="cursor-pointer shadow-2xl rounded-3xl bg-platinum">
			<div className=" h-56 rounded-3xl">
				<img className="w-full " src={foto} alt={nome}></img>
			</div>
			<p className=" break-keep w-[80%] rounded-full text-center bg-quicksilver font-semibold py-2 translate-x-[-50%] ml-[50%] mb-7 ">{nome}</p>
		</div>
	);
}

export default Card;
