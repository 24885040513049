import React from "react";
import { useTranslation } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();

  return (
    <div className="py-9 px-20 text-justify">
      <p className="font-bold">
        {t("pr_title_1")}
      </p>
      <p>
        {t("pr_txt_1")}
      </p>
      <p>
        {t("pr_txt_2")}
      </p>
      <a className="hover:underline" href="http://www.sarditable.it">http://www.sarditable.it</a>
      <p>{t("pr_txt_3")}</p>
      <p>
        {t("pr_txt_4")}
      </p>
      <p>
        {t("pr_txt_5")}
      </p>
      <p className="font-bold">
        {t("pr_title_2")}
      </p>
      <p>
        {t("pr_txt_6")}
      </p>
      <p>
        {t("pr_txt_7")}
      </p>
      <p>
        {t("pr_txt_8")}
      </p>
      <p className="font-bold">
        {t("pr_title_3")}
      </p>
      <p>
        {t("pr_txt_9")}
      </p>
      <p>{t("pr_txt_10")}</p>
      <p>
        {t("pr_txt_11")}
      </p>
      <p className="font-bold">
        {t("pr_title_4")}
      </p>
      <p className="font-semibold">
        {t("pr-subtitle_1")}
      </p>
      <p>
      {t("pr_txt_12")}
      </p>
      <p>
      {t("pr_txt_13")}
      </p>
      <p>
      {t("pr_txt_14")}
      </p>
      <p>
      {t("pr_txt_15")}
      </p>
      <p className="font-semibold">
      {t("pr-subtitle_2")}
      </p>
      <p>
      {t("pr_txt_16")}
      </p>
      <p>
      {t("pr_txt_17")}
      </p>
      <p>
        <strong>COOKIES</strong>
      </p>
      <p>
      {t("pr_txt_18")}
      </p>
      <p>
      {t("pr_txt_19")}
      </p>
      <p>
      {t("pr_txt_20")}
      </p>
      <p>
      {t("pr_txt_21")}
      </p>
      <p className="font-bold">
      {t("pr_title_6")}
      </p>
      <p>
      {t("pr_txt_22")}
      </p>
      <p>
      {t("pr_txt_23")}
      </p>
      <p className="font-bold">
      {t("pr_title_7")}
      </p>
      <p>
      {t("pr_txt_24")}
      </p>
      <p>
      {t("pr_txt_25")}
      </p>
      <p className="font-bold">
      {t("pr_title_8")}
      </p>
      <p>
      {t("pr_txt_26")}
      </p>
      <p>
      {t("pr_txt_27")}
      </p>
      <p>
      {t("pr_txt_28")}
      </p>
      <p>
      {t("pr_txt_29")}
        <a className='hover:underline' href="mailto:info@sarditable.it">info@sarditable.it</a>
      </p>
    </div>
  );
}

export default Privacy;
