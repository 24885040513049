export const regolamenti = {
  biliardi: [
    {
      title: "reg_bil_1",
      index: 0,
    },
    {
      title: "reg_bil_2",
      index: 1,
    },
    {
      title: "reg_bil_3",
      index: 2,
    },
  ],
  calciobalilla: [
    {
      title: "reg_calc",
      index: 0,
    },
  ],
  altrigiochi: [
    {
      title: "reg_shuff",
      index: 0,
    },
  ],
};
