import React, { useState } from "react";

function CTAbtn({ link, title }) {
  return (
    <button
      className="border-[3px] mt-8 shadow-md border-solid border-black ml-[50%] translate-x-[-50%]  h-auto w-auto bg-black text-white font-bold rounded-full px-12 py-auto hover:bg-gainsboro hover:text-black"
      onClick={() => {
        window.location.href = link;
      }}
    >
      <p className="">{title}</p>
    </button>
  );
}

export default CTAbtn;
