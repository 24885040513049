import React from "react";
import { useTranslation } from "react-i18next";

function Prodbiliardi() {
  const {t} = useTranslation();
  return (
    <div>
      <h2>{t('prod_bil_1')}</h2>
      <p>
        {t('prod_bil_2')}
      </p>
      <p>
        {t('prod_bil_3')}
      </p>
      <p>
        {t('prod_bil_4')}
      </p>
      <p>
        {t('prod_bil_5')}
      </p>
      <p>
        {t('prod_bil_6')}
      </p>
      <p>
        {t('prod_bil_7')}
      </p>
      <p>
        {t('prod_bil_8')}
      </p>
      <p>
        {t('prod_bil_9')}
      </p>
      <p>
        {t('prod_bil_10')}
      </p>
      <p>
        {t('prod_bil_11')}
      </p>
    </div>
  );
}

export default Prodbiliardi;
