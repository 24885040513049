import React, { useRef, useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

function BlogAperto() {
	const [contenuti, setContenuti] = useState("");
	const [contenuto, setContenuto] = useState("");
	const [loaded, setLoaded] = useState(false);
	const slug = useParams().id;

	const handleUpdate = () => {
		const headers = { "Access-Control-Allow-Origin": "*" };
		const name = "sardi";
		axios
			.post(
				"https://webinal.web.app/api/dash/getblog",
				{ name, slug },
				{ headers }
			)
			.then((res) => {
				console.log(res.data.contenuti);
				setContenuto(res.data.contenuti[0]);
				setLoaded(true);
			});
	};
	useEffect(() => {
		if (!loaded) {
			handleUpdate();
		} else {
		}
	});

	const htmlText = () => {
		return { __html: contenuto.testo };
	};
	return (
		<div className="w-full flex justify-center">
			<div className="w-[50%] flex flex-col">
				<img src={contenuto.img} alt="Not available" />
				<h2 className="mt-7 font-bold text-3xl">{contenuto.titolo} </h2>
				<h3 className="mt-3 font-semibold text-2xl">{contenuto.descr} </h3>
				<div
					id="testo"
					className="mt-3"
					dangerouslySetInnerHTML={htmlText()}
				></div>
			</div>
		</div>
	);
}

export default BlogAperto;
