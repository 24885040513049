import React from "react";
import blur from "../../Assets/Pictures/StandardImage.jpeg";
import { useTranslation } from "react-i18next";
function Dovesiamo() {
  const { t } = useTranslation();
  return (
    <div className="">
      <h1 className=" pt-10 text-center text-2xl font-bold"> {t('dove_siamo')}</h1>
      <div className="mt-4 w-screen bg-cultured">
        <h2 className="px-24 font-bold text-lg py-3">{t('la_sede')}</h2>
        <p className="px-24 font-medium">
          {t('la_sede_1')}
        </p>
        <p className="px-24 pb-4 font-medium">
          {t('la_sede_2')}
        </p>
      </div>
      <div className=" p-24 grid md:grid-cols-2 gap-36">
        <div className=" shadow-lg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1413.3229653338983!2d8.686463!3d44.889854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x58bc5e5cab65ef5c!2sSardi%20G%26D%20S.n.c.!5e0!3m2!1sit!2sit!4v1664270916381!5m2!1sit!2sit"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="shadow-lg">
          <iframe src="https://www.google.com/maps/embed?pb=!4v1680639358907!6m8!1m7!1sZ_cj3bQ9rMEZZQF_OaeV-Q!2m2!1d44.89021602571684!2d8.686770927400284!3f216.69373003278744!4f0.20347045563597987!5f0.7820865974627469" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
}

export default Dovesiamo;
