import React, { useState } from "react";

function ImgProdotto({ cat, img, title }) {
	const [indice, setIndice] = useState(0);

	return (
		<div className=" border-[1px] relative border-solid border-quicksilver">
			<div className="object-contain md:h-[48rem] ">
				<div className="absolute  bottom-0 left-[50%] translate-x-[-50%] translate-y-[100%] flex">
					{img.map((item, i) => {
						return (
							<div
								className={`w-20 m-2 p-1 bg-white border-2 border-solid ${
									i === indice ? "border-black" : "border-quicksilver"
								} rounded-xl`}
                                onClick={() => setIndice(i)}
							>
								<img
									src={require(`../../../Assets/Pictures/${cat}/${item}`)}
									alt={title}
								/>
							</div>
						);
					})}
				</div>
				<img
					src={require(`../../../Assets/Pictures/${cat}/${img[indice]}`)}
					alt={title}
					className="object-contain md:h-[48rem]"
				/>
			</div>
		</div>
	);
}

export default ImgProdotto;
