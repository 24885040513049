import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function FAQ() {
	const { t } = useTranslation();
	const QC = [
		"domanda1",
		"domanda2",
		"domanda3",
		"domanda4",
		"domanda5",
		"domanda6",
		"domanda7",
		"domanda8",
	];
	const AC = [
		"risposta1",
		"risposta2",
		"risposta3",
		"risposta4",
		"risposta5",
		"risposta6",
		"risposta7",
		"risposta8",
	];
	const QB = [
		"domanda9",
		"domanda10",
		"domanda11",
		"domanda12",
		"domanda13",
		"domanda14",
		"domanda15",
		"domanda16",
	];
	const AB = [
		"risposta9",
		"risposta10",
		"risposta11",
		"risposta12",
		"risposta13",
		"risposta14",
		"risposta15",
		"risposta16",
	];
	const [openB, setOpenB] = useState(0);
	const [openC, setOpenC] = useState(0);
	return (
		<div>
			<h1 className=" text-center text-3xl font-bold">FAQ</h1>{" "}
			<div className="md:grid grid-cols-2">
				<div className="p-4">
					<h3 className=" text-center text-xl font-semibold">
						{t("_calcio_balilla")}
					</h3>
					{QC.map((q, i) => {
						return (
							<div
								key={`domanda${i}`}
								className="my-1"
								onClick={() => {
									setOpenC(i);
								}}
							>
								<h2>
									<button
										type="button"
										className={`flex items-center justify-between w-full px-7 py-3 font-medium text-left border border-solid ${openC === i ? "border-black" : "border-gray-200"}  hover:bg-gray-100`}
										data-accordion-target="#accordion-collapse-body-1"
										aria-expanded="true"
										aria-controls="accordion-collapse-body-1"
									>
										<span>{t(q)}</span>
										<svg
											data-accordion-icon
											className="w-3 h-3 rotate-180 shrink-0"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 10 6"
										>
											<path
												stroke="currentColor"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 5 5 1 1 5"
											/>
										</svg>
									</button>
								</h2>
								<div
									className={`${openC === i ? "block" : "hidden"}`}
									aria-labelledby="accordion-collapse-heading-1"
								>
									<div className="p-5 border border-solid border-black">
										<p className="mb-2 ">{t(AC[i])}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="p-4">
					<h3 className=" text-center text-xl font-semibold">
						{t("_biliardi")}
					</h3>
					{QB.map((q, i) => {
						return (
							<div
								key={`domanda${i}`}
								className="my-1"
								onClick={() => {
									setOpenB(i);
								}}
							>
								<h2>
									<button
										type="button"
										className={`flex items-center justify-between w-full px-7 py-3 font-medium text-left border border-solid ${openB === i ? "border-black" : "border-gray-200"}  hover:bg-gray-100`}
										data-accordion-target="#accordion-collapse-body-1"
										aria-expanded="true"
										aria-controls="accordion-collapse-body-1"
									>
										<span>{t(q)}</span>
										<svg
											data-accordion-icon
											className="w-3 h-3 rotate-180 shrink-0"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 10 6"
										>
											<path
												stroke="currentColor"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M9 5 5 1 1 5"
											/>
										</svg>
									</button>
								</h2>
								<div
									className={`${openB === i ? "block" : "hidden"}`}
									aria-labelledby="accordion-collapse-heading-1"
								>
									<div className="p-5 border border-solid border-black">
										<p className="mb-2 ">{t(AB[i])}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default FAQ;
