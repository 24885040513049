/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import Schedaprodotto from "../SchedaProdotto/Schedaprodotto";
import { products } from "../../../Resources/products";

import { regolamenti } from "../../../Resources/regolamenti";
import Infobiliardi from "../Informazioni/Biliardi/Infobiliardi";
import Prodbiliardi from "../Informazioni/Biliardi/Prodbiliardi";
import Scopobiliardi from "../Informazioni/Biliardi/Scopobiliardi";
import "../../Molecules/Informazioni/info.css";
import Infoshuffleboard from "../Informazioni/Altrigiochi/Infoshuffleboard";
import Infocalciobalilla from "../Informazioni/Infocalciobalilla/Infocalciobalilla";
import { Dropdown } from 'flowbite-react'
import { useTranslation } from "react-i18next";



function Prodotti({ title, slug }) {
  const { t } = useTranslation();
  const [scelta, setScelta] = useState("0");
  const [prodotti, setProdotti] = useState([]);
  const [info, setinfo] = useState([]);
  const [sceltainfo, setSceltaInfo] = useState("0");

  if (!prodotti.length) {
    switch (slug) {
      case "biliardi":
        setProdotti(products.Biliardi);
        setinfo(regolamenti.biliardi);
        break;
      case "calciobalilla":
        setProdotti(products.Calciobalilla);
        setinfo(regolamenti.calciobalilla);

        break;
      case "altrigiochi":
        setProdotti(products.Altrigiochi);
        setinfo(regolamenti.altrigiochi);

        break;
      default:
        setProdotti(products.Biliardi);

        break;
    }
  }

  return (
    <>
      <h1 className="mt-8 text-center font-bold text-2xl uppercase">{title}</h1>

      <div className="mt-5 w-full flex justify-center px-0">

        <Dropdown
          label={scelta === '0' ? t('dropdown_1') : scelta === '1' ? t('dropdown_2') : scelta === '2' ? t('dropdown_3') : t('dropdown_4')}
          inline={true}
          onClick={(e) => {
            setScelta(e.target.value);
            console.log(e.target.value);
          }}          
        >
          <Dropdown.Item onClick={() => {
            setScelta("0");
          }}>
            {t('dropdown_1')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            setScelta("1");
          }} >
            {t('dropdown_2')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            setScelta("2");
          }}>
            {t('dropdown_3')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => {
            setScelta("3");
          }}>
            {t('dropdown_4')}
          </Dropdown.Item>

        </Dropdown>
      </div>

      {scelta === "3" ? (
        <div>
          <div className=" flex w-full justify-center mt-7 ml-[50%] translate-x-[-50%]">
            {info.map((row, i) => {
              return (
                <p
                  className={`${sceltainfo === i && "font-bold"
                    } cursor-pointer px-10 mb`}
                  onClick={() => setSceltaInfo(i)}
                >
                  {t(row.title)}
                </p>
              );
            })}
          </div>
          <div className="mt-7 bg-cultured py-7 md:px-16 px-8">
            {slug === "biliardi" ? (
              sceltainfo === 0 ? (
                <Infobiliardi />
              ) : sceltainfo === 1 ? (
                <Prodbiliardi />
              ) : (
                <Scopobiliardi />
              )
            ) : slug === "altrigiochi" ? (
              <Infoshuffleboard />
            ) : (
              slug === "calciobalilla" && <Infocalciobalilla />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="grid md:grid-cols-3 mt-5 md:gap-y-16 gap-y-32">
            {prodotti.length > 0 && scelta === "0" ? (
              prodotti.map((prodotto, i) => {
                return <Schedaprodotto prodotto={prodotto} slug={slug} />;
              })
            ) : scelta === "1" ? (
              prodotti.map((prodotto, i) => {
                if (prodotto.gettoniera) {
                  return <Schedaprodotto prodotto={prodotto} slug={slug} />;
                }
              })
            ) : scelta === "2" ? (
              prodotti.map((prodotto, i) => {
                if (!prodotto.gettoniera) {
                  return <Schedaprodotto prodotto={prodotto} slug={slug} />;
                }
              })
            ) : (
              <p>Not found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Prodotti;

/*
return <Schedaprodotto prodotto={prodotto} slug={slug} />

*/

//<DropDownMenu label='Seleziona' items={[{ label: 'Tutti' }, { label: 'Con Gettoniera' }, { label: 'Senza Gettoniera' }, { label: 'Informazioni', withDivider: 'true' }]} />