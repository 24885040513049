import React from "react";
import blur from "../../Assets/Pictures/StandardImage.jpeg";
import { useTranslation } from "react-i18next";


function Personalizzazioni() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h1 className=" pt-10 text-center text-2xl font-bold">
          {t("pers_title")}
        </h1>
        <div className="mt-4 w-screen bg-cultured">
          <p className="py-4 md:px-24 px-8 pb-4 font-medium">
           {t("pers_txt_1")}
          </p>
          <p className="py-4 md:px-24 text-sm px-8 pb-4 font-medium">
           {t("pers_txt_2")}
          </p>
        </div>
        <div className=" p-24 grid md:grid-cols-3 gap-36">
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/albani.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/aperol.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/ares-champions.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/cescon.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/coca-cola.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/continental.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/gatorade.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/gazzetta.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/holland.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/johnpitstop.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/kenzo.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/konig-pilsener.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/pepsiboom.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/ricreativo.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/ringo.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/sassuolo.jpg")} alt="" />
          </div>
          <div className=" ">
            <img width="100%" src={require("../../Assets/Pictures/personalizzazioni/skyvr46.jpg")} alt="" />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Personalizzazioni;
