import Foto1 from "./foto1.png";
import Foto2 from "./foto2.png";
import Foto3 from "./foto3.png";

import P1 from "./prodotti1.PNG";
import P2 from "./prodotti2.PNG";
import P3 from "./prodotti3.PNG";
import P4 from "./prodotti4.PNG";

export const Foto = {Foto1, Foto2, Foto3};
export const Prodotti = {P1, P2, P3, P4};