import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Molecules/Navbar/navbar";
import Home from "./pages/Home/Home";
import Footer from "./Components/Molecules/Footer/Footer";
import Dovesiamo from "./pages/dovesiamo/Dovesiamo";
import Qualità from "./pages/qualità/Qualità";
import Contatti from "./pages/contatti/Contatti";
import Servizio from "./pages/servizio/Servizio";
import Personalizzazioni from "./pages/personalizzazioni/Personalizzazioni";
import Storia from "./pages/storia/Storia";
import Biliardi from "./pages/biliardi/Biliardi";
import PaginaProdotto from "./Components/Molecules/PaginaProdotto/PaginaProdotto";
import Calciobalilla from "./pages/calciobalilla/calciobalilla";
import Altrigiochi from "./pages/altrigiochi/Altrigiochi";
import Privacy from "./pages/Privacy/Privacy";
import Blog from "./pages/Blog/Blog";
import BlogAperto from "./pages/Blog/BlogAperto";
import { React, Suspense, useState } from "react";
import "./i18n";
import NavbarCopy from "./Components/Molecules/Navbar/navbar copy";
import CookieConsent from "react-cookie-consent";
import FAQ from "./Components/Molecules/FAQ/FAQ";

function App() {
	const [lang, setLang] = useState("en");

	return (
		<div>
			<Suspense fallback={null}>
				<BrowserRouter>
					<NavbarCopy />


					<CookieConsent
						location="bottom"
						buttonText="I understand"
						cookieName="eula"
						style={{ background: "#222" }}
						buttonStyle={{ color: "#000", fontSize: "13px" }}
						expires={150}
					>
						This website uses only necessary cookies.{" "}
						
					</CookieConsent>



					<div className=" pt-20 overflow-x-hidden">
						<Routes>
							<Route path={`/home`} element={<Home />} />
							<Route path={`/`} element={<Home />} />
							<Route path={`/contatti/`} element={<Contatti />} />
							<Route path={`/blog`} element={<Blog />} />
							<Route path={`/faq`} element={<FAQ />} />
							<Route path={`/blog/:id`} element={<BlogAperto />} />
							<Route path={`/privacy/`} element={<Privacy />} />
							<Route path={`/contatti/dovesiamo`} element={<Dovesiamo />} />
							<Route path={`/azienda/qualita`} element={<Qualità />} />
							<Route path={`/azienda/servizio`} element={<Servizio />} />
							<Route path={`/azienda/storia`} element={<Storia />} />
							<Route path={`/biliardi`} element={<Biliardi />} />
							<Route
								path={`/personalizzazioni`}
								element={<Personalizzazioni />}
							/>
							<Route
								path={`/biliardi/:slug`}
								element={<PaginaProdotto cat={"biliardi"} />}
							/>
							<Route path={`/calciobalilla`} element={<Calciobalilla />} />
							<Route
								path={`/calciobalilla/:slug`}
								element={<PaginaProdotto cat={"calciobalilla"} />}
							/>
							<Route path={`/altrigiochi`} element={<Altrigiochi />} />
							<Route
								path={`/altrigiochi/:slug`}
								element={<PaginaProdotto cat={"altrigiochi"} />}
							/>
						</Routes>
					</div>
					<Footer />
				</BrowserRouter>
			</Suspense>
		</div>
	);
}

export default App;
