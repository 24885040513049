import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { products } from "../../../Resources/products";
import Schedaprodotto from "../SchedaProdotto/Schedaprodotto";
import "./Paginaprodotto.css";
import CTAbtn from "../../Atoms/CTAbtn/CTAbtn";
import SBMTbtn from "../../Atoms/SBMTbtn/SBMTbtn";
import Contacts from "../Contacts/Contacts";
import ImgProdotto from "../ImgProdotto/ImgProdotto";
import { useTranslation } from "react-i18next";

function PaginaProdotto({ cat }) {
	const { t } = useTranslation();
	const [title, setTitle] = useState(null);
	const [modello, setmodello] = useState(null);
	const [gettoniera, setgettoniera] = useState(null);
	const [dimensioni, setdimensioni] = useState(null);
	const [campo, setcampo] = useState(null);
	const [buche, setBuche] = useState(null);
	const [imballo, setImballo] = useState(null);
	const [pesol, setpesol] = useState(null);
	const [peson, setpeson] = useState(null);
	const [dotazione, setDotazione] = useState(null);
	const [place, setPlace] = useState(null);
	const [descr, setDescr] = useState(null);
	const [panno, setpanno] = useState(null);
	const [text, setText] = useState(null);
	const [img, setImg] = useState(null);
	const [img2, setImg2] = useState(null);
	const [img3, setImg3] = useState(null);
	const [img4, setImg4] = useState(null);
	const [loaded, setloaded] = useState(false);
	const [dimest, setdimest] = useState(null);
	const [dimast, setdimast] = useState(null);
	const [opzione, setopzione] = useState(null);
	const [piano, setpiano] = useState(null);

	const prod_slug = useParams().slug;

	const [prodotti, setProdotti] = useState([]);

	const chooseCat = () => {
		if (!prodotti.length) {
			switch (cat) {
				case "biliardi":
					setProdotti(products.Biliardi);
					break;
				case "calciobalilla":
					setProdotti(products.Calciobalilla);
					break;
				case "altrigiochi":
					setProdotti(products.Altrigiochi);
					break;

				default:
					setProdotti(products.Biliardi);

					break;
			}
		}
	};

	const LoadData = () => {
		if (!loaded) {
			prodotti.map((prodotto, i) => {
				if (prodotto.slug === prod_slug) {
					if (prodotto.title) {
						setTitle(prodotto.title);
					}
					if (prodotto.modello) {
						setmodello(prodotto.modello);
					}
					if (prodotto.gettoniera) {
						setgettoniera(prodotto.gettoniera);
					}
					if (prodotto.dimensioni) {
						setdimensioni("dotazione_" + prod_slug);
					}
					if (prodotto.buche) {
						setBuche(prodotto.buche);
					}
					if (prodotto.campo) {
						setcampo(prodotto.campo);
					}
					if (prodotto.imballo) {
						setImballo(prodotto.imballo);
					}
					if (prodotto.pesol) {
						setpesol(prodotto.pesol);
					}
					if (prodotto.peson) {
						setpeson(prodotto.peson);
					}
					if (prodotto.dotazione) {
						setDotazione(prodotto.dotazione);
					}
					if (prodotto.place) {
						setPlace(prodotto.place);
					}
					if (prodotto.descr) {
						setDescr(prodotto.descr);
					}
					if (prodotto.text) {
						setText(prodotto.text);
					}
					if (prodotto.img) {
						console.log(prodotto.img);
						setImg(prodotto.img);
					}
					if (prodotto.panno) {
						setpanno(prodotto.panno);
					}

					if (prodotto.dimest) {
						setdimest(prodotto.dimest);
					}
					if (prodotto.dimast) {
						setdimast(prodotto.dimast);
					}
					if (prodotto.piano) {
						setpiano(prodotto.piano);
					}
					if (prodotto.opzione) {
						setopzione(prodotto.opzione);
					}
				}
				setloaded(true);
			});
		}
	};

	chooseCat();
	LoadData();

	const handleClickScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const tabella = [
		{
			title: "_modello",
			var: t(modello),
		},
		{
			title: "_campo",
			var: t(campo),
		},
		{
			title: "_dimensioni",
			var: t(dimensioni),
		},
		{
			title: "_panno",
			var: t(panno),
		},

		{
			title: "_imballo",
			var: t(imballo),
		},
		{
			title: "_buche",
			var: t(buche),
		},
		{
			title: "Peso lordo",
			var: t(pesol),
		},
		{
			title: "peso_n",
			var: t(peson),
		},
		{
			title: "_dotazione",
			var: t(dotazione),
		},
		{
			title: "dim_est",
			var: t(dimest),
		},
		{
			title: "dim_ast",
			var: t(dimast),
		},
		{
			title: "_piano",
			var: t(piano),
		},
		{
			title: "_opzione",
			var: t(opzione),
		},
	];

	console.log("dimast", dimast);

	return (
		<div>
			{loaded ? (
				<div>
					{" "}
					<div className=" grid md:grid-cols-2 py-24 ">
						

						<div className="bg-platinum border-[1px] border-solid border-quicksilver">
							<h1 className="mt-7 text-3xl uppercase font-bold text-center">
								{t(title)}
							</h1>
							<p className=" mt-6 font-semibold uppercase text-center">
								{t(place)}
							</p>
							<p className=" md:px-24 px-8 mt-8 text-justify font-medium">{t(text)}</p>
							<div className="grid grid-cols-1 mt-8 pb-8">
								<div className="flex justify-center w-[100%]">
									<div className="grid md:grid-cols-2 gap-7 pb-5">
										<button
											className="h-12 px-5 bg-gainsboro text-black font-bold rounded-full hover:bg-black hover:text-white"
											onClick={() => handleClickScroll("tabella")}
										>
											<p> {t("_caratt")} </p>
										</button>
										<a
											href={"/PDF/" + prod_slug + ".pdf"}
											target="_blank"
											rel="noreferrer"
											className="h-12 px-5 text-center pt-3 bg-gainsboro text-black font-bold rounded-full hover:bg-black hover:text-white"
										>
											{t("scheda_pdf")}
										</a>
									</div>
								</div>
								<button
									className="border-[3px] shadow-md border-solid border-black ml-[50%] translate-x-[-50%] mt-6 h-auto w-auto bg-black text-white font-bold rounded-full px-12 py-auto hover:bg-gainsboro hover:text-black"
									onClick={() => handleClickScroll("contatti2")}
								>
									<p> {t("_info")} </p>
								</button>{" "}
							</div>
						</div>
						<ImgProdotto title={title} img={img} cat={cat} />
					</div>


					<section id="tabella" className="pt-10">
						<h3 className=" mt-16 text-center font-bold text-3xl">
							{t("_caratt")}
						</h3>
						<table className="md:w-[65vw] w-full mt-9 md:ml-[50%] md:translate-x-[-50%]">
							{tabella.map((riga, i) => {
								if (riga.var) {
									if (Array.isArray(riga.var)) {
										return (
											<tr>
												<th className="md:w-[50%] text-left bg-gainsboro border-x-0 border-[1px] border-solid border-onyx">
													{t(riga.title)}
												</th>
												<th className="md:w-[50%] text-left border-x-0 border-[1px] border-solid border-onyx break-words">
													{riga.var[0]}
													<br />
													{riga.var[1]}
												</th>
											</tr>
										);
									} else {
										return (
											<tr>
												<th className="md:w-[50%] text-left bg-gainsboro border-x-0 border-[1px] border-solid border-onyx">
													{t(riga.title)}
												</th>
												<th className="md:w-[50%] text-left border-x-0 border-[1px] border-solid border-onyx break-words">
													{riga.var}
												</th>
											</tr>
										);
									}
								}
							})}
						</table>
					</section>
					<section id="contatti2" className="pt-10">
						<Contacts />
					</section>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
}

export default PaginaProdotto;

/*          VECCHIA TABELLA
			  <table className="m-3">
				{modello && (
				  <tr>
					<th>Modello</th>
					<th>{modello}</th>
				  </tr>
				)}
				{panno && (
				  <tr>
					<th>Panno</th>
					<th>{panno}</th>
				  </tr>
				)}
				{campo && (
				  <tr>
					<th>Campo di gioco</th>
					<th>{campo}</th>
				  </tr>
				)}
				{buche && (
				  <tr>
					<th>Misura buche</th>
					<th>{buche}</th>
				  </tr>
				)}
				{dotazione && (
				  <tr>
					<th>Dotazione</th>
					<th>{dotazione}</th>
				  </tr>
				)}
			  </table>
*/
