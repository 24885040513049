import React from "react";
import CTAbtn from "../CTAbtn/CTAbtn";
import {useTranslation} from 'react-i18next'

function CTAsec({titolo, children, link}) {
	const {t} = useTranslation();
	return (
		<section className="p-6 mt-12">
			<div className="text-center">
				<h1 className="text-3xl uppercase font-semibold py-5">{t(titolo)}</h1>
			</div>
			<div className="p-10 bg-gainsboro text-justify rounded-xl shadow-lg">
				{children}
				<CTAbtn link={link} title={t('_scopri')}/> 
			</div>
		</section>
	);
}

export default CTAsec;
