import React, { useState } from "react";
import CTAbtn from "../../Atoms/CTAbtn/CTAbtn";
import { useTranslation } from "react-i18next";


function Schedaprodotto2({ prodotto, slug }) {

  const [immagine, setImmagine] = useState(prodotto.img[0]);
  const { t } = useTranslation();

  return (
    <div className="grid md:grid-cols-2 place-content-between">
      <a href={`/${slug}/${prodotto.slug}`}>
      <img

        className=" ml-[50%] translate-x-[-50%] px-20 transition-all duration-500"
        src={require(`../../../Assets/Pictures/${slug}/${immagine}`)}
        alt=""
        height={"100%"}
      />
      </a>
      <div className="p-6">
        <h1 className=" font-semibold text-xl">{t(prodotto.title)}</h1>
        <p className=" font-semibold">{t(prodotto.place)}</p>
        <p className="">{t(prodotto.descr)}</p>
      <div className="">

      <CTAbtn link={`${slug}/${prodotto.slug}`} title={t('_scopri')}></CTAbtn>
      </div>
      </div>
    </div>
  );
}

export default Schedaprodotto2;
