import React from "react";
import coppi from "../../Assets/Pictures/coppi.jpg";
import sportfoot from "../../Assets/Pictures/sportfoot.jpg";
import { useTranslation } from "react-i18next";

function Storia() {
	const { t } = useTranslation();

	return (
		<div className="">
			<h1 className=" pt-10 text-center text-2xl font-bold">{t("_storia")}</h1>
			<div className="mt-4 md:px-24 px-8 w-screen bg-cultured">
				<h2 className=" font-bold text-lg py-3 uppercase">
					{t("storia_1_title")}
				</h2>
				<p className=" pb-4 font-medium">{t("storia_1_descr")}</p>
				<h2 className=" font-bold text-lg py-3 uppercase">
					{" "}
					{t("storia_2_title")}{" "}
				</h2>
				<p className=" pb-4 font-medium">{t("storia_2_descr")}</p>
			</div>
			<div className=" p-24 grid md:grid-cols-2 gap-36">
				<div className="">
					<img className=" shadow-lg" width="100%" src={sportfoot} alt="" />
					<h2 className="pt-6 font-bold text-center">{t("storia_1_elem")}</h2>
					<p className="text-center">{t("storia_1_elem_d")}</p>
				</div>
				<div className="">
					<img className="shadow-lg"   src={coppi} alt="" />
					
				</div>
			</div>
		</div>
	);
}

export default Storia;
