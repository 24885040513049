import React from "react";
import CTAbtn from "../CTAbtn/CTAbtn";
import { Card } from "flowbite-react";

function Content({ img, title, descr, url }) {
	return (
		<div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
			<a href={'/blog/'+url}>
				<div className=" ">
					<img className="rounded-t-lg w-[24rem]  h-[18rem]  object-cover" src={img} alt="" />
				</div>
			</a>
			<div className="p-5">
				<a href={'/blog/'+url}>
					<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						{title}
					</h5>
				</a>
				<p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
					{descr}
				</p>
				<a
					href={'/blog/'+url}
					className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-black rounded-lg "
				>
					Read more
					<svg
						aria-hidden="true"
						className="w-4 h-4 ml-2 -mr-1"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
							clip-rule="evenodd"
						></path>
					</svg>
				</a>
			</div>
		</div>
	);
}

export default Content;

/*
<div classNameName="w-full md:flex items-center justify-between space-x-16 bg-cultured rounded-3xl mt-3">
			<div classNameName="flex justify-center flex-col md:flex-row">
				<div className=" w-52 p-4 ">
					<img src={img} alt="Not available" />
				</div>
				<div className="flex items-center justify-center px-12 ">
					<div>
						<p className="font-semibold">{title}</p>
						<p className="">{descr}</p>
					</div>
				</div>
			</div>
			<div className="">
				<CTAbtn link={`/blog/${url}`} title="Scopri di più" />
			</div>
		</div>
*/
