import React, { useEffect, useState } from "react";
import Content from "../../Components/Atoms/Content/Content";
import axios from "axios";

function Blog() {
	const [contenuti, setContenuti] = useState("");
	const [loaded, setLoaded] = useState(false);

	const handleUpdate = () => {
		const headers = { "Access-Control-Allow-Origin": "*" };
		const name = "sardi";
		axios
			.post(
				"https://webinal.web.app/api/dash/getblogs",
				{ name },
				{ headers }
			)
			.then((res) => {
				console.log(res.data.contenuti);
				setContenuti(res.data.contenuti);
				setLoaded(true);
			});
	};
	useEffect(() => {
		if (!loaded) {
			handleUpdate();
		} else {
		}
	});

	return (
		<div className="w-full md:px-24 px-2 flex flex-col justify-items-center items-center">
			<h1 className=" py-10 text-center text-2xl font-bold">BLOG</h1>
			<div className="grid md:grid-cols-3 gap-20">
			{loaded && contenuti.length && contenuti.map((art,i)=>{
                return <Content key={i} url={art._id} img={art.img} title={art.titolo} descr={art.descr}/>
            })}
			</div>
			
		</div>
	);
}

export default Blog;
