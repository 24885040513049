import React from "react";
import { useTranslation } from "react-i18next";


function Scopobiliardi() {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("scopo_bil_1")}</h2>
      <p>
        {t("scopo_bil_2")}
      </p>
      <h2>{t("scopo_bil_3")}</h2>
      <ul>
        <li className="linfo">
          {t("scopo_bil_4")}
        </li>
        <li className="linfo">
          {t("scopo_bil_5")}
        </li>
        <li className="linfo">
          {t("scopo_bil_6")}
        </li>
        <li className="linfo">
          {t("scopo_bil_7")}
        </li>
        <li className="linfo">
          {t("scopo_bil_8")}
        </li>
        <li className="linfo">
          {t("scopo_bil_9")}
        </li>
      </ul>
      <h2>{t("scopo_bil_10")}</h2>
      <p>
        {t("scopo_bil_11")}
      </p>
      <ul>
        <li className="linfo">{t("scopo_bil_12")}</li>
        <li className="linfo">{t("scopo_bil_13")}</li>
      </ul>
      <h2>
        {t("scopo_bil_14")}
      </h2>
      <p>{t("scopo_bil_15")}</p>
      <ul>
        <li className="linfo">
          {t("scopo_bil_16")}
        </li>
        <li className="linfo">{t("scopo_bil_17")}</li>
        <li className="linfo">
          {t("scopo_bil_18")}
        </li>
        <li className="linfo">{t("scopo_bil_19")}</li>
        <li className="linfo">
          {t("scopo_bil_20")}
        </li>
        <li className="linfo">{t("scopo_bil_21")}</li>
        <li className="linfo">
          {t("scopo_bil_22")}
        </li>
        <li className="linfo">{t("scopo_bil_22")}</li>
        <li className="linfo">{t("scopo_bil_23")}</li>
        <li className="linfo">{t("scopo_bil_24")}</li>
        <li className="linfo">{t("scopo_bil_25")}</li>
        <li className="linfo">{t("scopo_bil_26")}</li>
        <li className="linfo">{t("scopo_bil_27")}</li>
      </ul>
      <h2>{t("scopo_bil_28")}</h2>
      <ul>
        <li className="linfo">
          {t("scopo_bil_29")}
        </li>
        <li className="linfo">
          {t("scopo_bil_30")}
        </li>
      </ul>
      <p>
        {t("scopo_bil_31")}
      </p>
      <h2>{t("scopo_bil_32")}</h2>
      <p>
        {t("scopo_bil_33")}
      </p>
      <p>
        {t("scopo_bil_34")}
      </p>
      <p>
        {t("scopo_bil_35")}
      </p>
      <p>
        {t("scopo_bil_36")}
      </p>
      <h2>{t("scopo_bil_37")}</h2>
      <p>
        {t("scopo_bil_38")}
      </p>
      <p>
        {t("scopo_bil_30")}
      </p>
      <ul>
        <li className="linfo">{t("scopo_bil_40")}</li>
        <li className="linfo">
          {t("scopo_bil_41")}
        </li>
        <li className="linfo">
          {t("scopo_bil_42")}
        </li>
      </ul>
      <p>
        {t("scopo_bil_43")}
      </p>
    </div>
  );
}

export default Scopobiliardi;
