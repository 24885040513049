import React from "react";
import stabilimento from "../../Assets/Pictures/stabilimento.jpg"
import { useTranslation } from "react-i18next";

function Servizio() {
	const { t } = useTranslation();
	return (
		<div className="">
			<h1 className=" pt-10 text-center text-2xl font-bold">
				{" "}
				{t("_servizio")}{" "}
			</h1>
			<div className="mt-4 md:px-24 px-8 w-screen bg-cultured">
				<h2 className=" font-bold text-lg py-3">
					{t("servizio_1_title")}
				</h2>
				<p className=" pb-4 font-medium">{t("servizio_1_desc")}</p>
				<h2 className=" font-bold text-lg py-3">
					{t("servizio_2_title")}
				</h2>
				<p className=" pb-4 font-medium">{t("servizio_2_desc")}</p>
			</div>
			<img className="p-7" src={stabilimento} alt="STABILIMENTO" />
		</div>
	);
}

export default Servizio;
