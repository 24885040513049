import React from "react";
import Prodotti from "../../Components/Molecules/Prodotti/Prodotti";
import { useTranslation } from "react-i18next";

function Biliardi() {
  const { t } = useTranslation();

  return (
    <div>
      <Prodotti slug="biliardi" title={t("_biliardi")}/>
    </div>
  );
}

export default Biliardi;
