import React from "react";
import Prodotti from "../../Components/Molecules/Prodotti/Prodotti";
import { useTranslation } from "react-i18next";

function Altrigiochi() {
  const { t } = useTranslation();

  return (
    
    <div>
      <Prodotti slug="altrigiochi" title={t("_altrigiochi")}/>
    </div>
  );
}

export default Altrigiochi;

