import React from "react";
import { useTranslation } from "react-i18next";

function Infoshuffleboard() {
  const { t } = useTranslation()

  
  return (
    <div>
      <section>
        <h2>{t('i_shuff_title')}</h2>
        <p>{t('i_shuff_1')}</p>
        <h2>{t('i_shuff_title_2')}</h2>
        <ul>
          <li className="linfo">{t('i_shuff_2')}</li>
          <li className="linfo">{t('i_shuff_3')}</li>
        </ul>
        <h2>{t('i_shuff_title_3')}</h2>
        <ul>
          <li className="linfo">{t('i_shuff_4')}</li>
          <li className="linfo">{t('i_shuff_5')}</li>
          <li className="linfo">{t('i_shuff_6')}</li>
          <li className="linfo">{t('i_shuff_7')}</li>
          <li className="linfo">{t('i_shuff_8')}</li>
          <li className="linfo">{t('i_shuff_9')}</li>
          <li className="linfo">{t('i_shuff_10')}</li>
          <li className="linfo">{t('i_shuff_11')}</li>
          <li className="linfo">{t('i_shuff_12')}</li>
        </ul>
        <h2>{t('i_shuff_title_4')}</h2>
        <p>{t('i_shuff_13')}</p>
        <h2>{t('i_shuff_title_5')}</h2>
        <ul>
          <li className="linfo">{t('i_shuff_14')}</li>
          <li className="linfo">{t('i_shuff_15')}</li>
          <li className="linfo">{t('i_shuff_16')}</li>
          <li className="linfo">{t('i_shuff_17')}</li>
        </ul>
      </section>
    </div>
  );
}

export default Infoshuffleboard;
