import React from "react";
import { useTranslation } from "react-i18next";

function Infocalciobalilla() {
  const { t } = useTranslation();
  return (
    <div>
      <section>
        <h2>
          {t("info-calc_1")}
        </h2>
        <p>
          {t("info-calc_2")}
        </p>
      </section>
      <section>
        <h2>
          {t("info-calc_3")}
        </h2>
        <p>
          {t("info-calc_4")}
        </p>
        <p>
          {t("info-calc_5")}
        </p>
        <p>
          {t("info-calc_6")}
        </p>
      </section>
      <section>
        <h2>
          {t("info-calc_7")}
        </h2>
        <p>
          {t("info-calc_8")}
        </p>
        <p>
          {t("info-calc_9")}
        </p>
      </section>
      <section>
        <h2>
          {t("info-calc_10")}
        </h2>
        <p>
          {t("info-calc_11")}
        </p>
        <p>
          {t("info-calc_12")}
        </p>
        <p> {t("info-calc_13")}
        </p>
        <ul>
          <li className="linfo">
            {t("info-calc_15")}
          </li>
          <li className="linfo">
            {t("info-calc_16")}
          </li>
          <li className="linfo">
            {t("info-calc_17")}
          </li>
        </ul>
        <p>
          {t("info-calc_18")}
        </p>
        <p>
          {t("info-calc_19")}
        </p>
        <p>
          {t("info-calc_20")}
        </p>
      </section>
      <section>
        <h2>
          {t("info-calc_21")}
        </h2>
        <p>
          {t("info-calc_22")}
        </p>
        <p>
          {t("info-calc_23")}
        </p>
        <h2>
          {t("info-calc_24")}
        </h2>
        <p>
          {t("info-calc_25")}
        </p>
      </section>
    </div>
  );
}

export default Infocalciobalilla;
