export const products = {
	Biliardi: [
		{
			title: "Pool table",
			modello: "Pool Table",
			slug: "pool",
			gettoniera: true,
			panno: "panno_pooltable",
			campo: "campo_pooltable",
			buche: "buche_pooltable",
			dotazione: "dotazione_pooltable",
			place: "place_pooltable",
			descr: "descr_pooltable",
			text: "text_pooltable",
			img: ["poolnera.png"],
		},
		{
			title: "Pool Seahawk",
			slug: "seahawk",
			gettoniera: false,
			campo: "cm. 200 x 100 - 7",
			buche: "mm. 95",
			pesol: "Kg. 235",
			dotazione: "dotazione_seahawk",
			place: "place_seahawk",
			descr: "descr_seahawk",
			text: "text_seahawk",
			img: ["Seahawk.png"],
		},
		{
			title: "Pool Camping",
			slug: "camping",
			gettoniera: false,
			campo: "cm. 200 x 100 - 7",
			panno: "panno_camping",
			buche: "mm. 95",
			pesol: "Kg. 280",
			dotazione: "dotazione_camping",
			place: "place_camping",
			descr: "descr_camping",
			text: "text_camping",
			img: ["pool-americano-camping.jpg"],
		},
		{
			title: "Aladin",
			slug: "aladin",
			gettoniera: false,
			panno: "panno_aladin",
			buche: "buche_aladin",
			dotazione: "dotazione_aladin",
			place: "place_aladin",
			descr: "descr_aladin",
			text: "text_aladin",
			img: ["aladin 4.jpg", "Aladin 5.jpg", "aladin cop 1.jpg",],
		},
		{
			title: "Eagle",
			slug: "eagle",
			gettoniera: false,
			panno: "panno_eagle",
			buche: "buche_eagle",
			dotazione: "dotazione_eagle",
			place: "place_eagle",
			descr: "descr_eagle",
			text: "text_eagle",
			img: ["eagle1.png", "eagle2.png", "eagle3.png", "eagle4.png", "eagle5.png"],
		},
		{
			title: "Venus",
			modello: "Pool Table",
			slug: "venus",
			gettoniera: false,
			panno: "panno_venus",
			buche: "buche_venus",
			dotazione: "dotazione_venus",
			place: "place_venus",
			descr: "descr_venus",
			text: "text_venus",
			img: ["venus1.png", "venus2.png", "venus3.png"],
		},
		{
			title: "Pierrot",
			slug: "pierrot",
			gettoniera: false,
			panno: "panno_pierrot",
			buche: "buche_pierrot",
			dotazione: "dotazione_pierrot",
			place: "place_pierrot",
			descr: "descr_pierrot",
			text: "text_pierrot",
			img: ["pierrot1.png", "pierrot2.png", "pierrot3.png", "pierrot4.png"],
		},
		{
			title: "Charleston",
			slug: "charleston",
			gettoniera: false,
			dimensioni: "dimensioni_charleston",
			dotazione: "dotazione_charleston",
			place: "place_charleston",
			descr: "descr_charleston",
			text: "text_charleston",
			img: ["charleston1.png", "charleston2.png", "charleston3.png", "charleston4.png", "charleston5.png"],
		},
	],
	Calciobalilla: [
		{
			title: "Ares",
			slug: "ares",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_ares_usc", "dimast_ares_tel"],
			dotazione: "dotazione_ares",
			campo: "campo_ares",
			place: "place_ares",
			text: "text_ares",
			descr: "descr_ares",
			img: [
				"ares-calcio-balilla.jpg",
				"calcio-balilla-gazzetta-dello-sport.jpg",
				"ares border.jpg",
			],
		},
		{
			title: "Atlantis",
			slug: "atlantis",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_atlantis_usc", "dimast_atlantis_tel"],
			imballo: "cm. 151 x 78 x 35",
			dotazione: "dotazione_atlantis",
			campo: "campo_atlantis",
			pesol: "kg. 58",
			place: "place_atlantis",
			text: "text_atlantis",
			descr: "descr_atlantis",
			img: ["atlantis-bianco-nuovo.jpg", "calcio-balilla-atlantis-min.jpg"],
		},
		{
			title: "Atlantis Lux",
			slug: "atlantislux",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_atlantislux_usc", "dimast_atlantislux_tel"],
			imballo: "cm. 151 x 78 x 35",
			dotazione: "dotazione_atlantislux",
			pesol: "kg. 58",
			campo: "campo_atlantislux",
			place: "place_atlantislux",
			text: "text_atlantislux",
			descr: "descr_atlantislux",
			img: ["Atlantis Lux nero 1.jpg", "Atlantis Lux Maul 1.jpg"],
		},
		{
			title: "Flash",
			slug: "flash",
			gettoniera: false,
			dimest: "	cm. 142 x 76 x 90",
			dimast: ["dimast_flash_usc", "dimast_flash_tel"],
			dotazione: "dotazione_flash",
			campo: "campo_flash",
			pesol: "kg. 52",
			imballo: "cm. 151 x 78 x 32",
			place: "place_flash",
			text: "text_flash",
			descr: "descr_flash",
			img: ["flash sopra.jpg", "flash fianco.jpg", "calcio-balilla-flash.jpg"],
		},
		{
			title: "Faxe",
			slug: "faxe",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_faxe_usc", "dimast_faxe_tel"],
			dotazione: "dotazione_faxe",
			imballo: "cm. 151 x 78 x 44",
			pesol: "kg. 75",
			campo: "campo_faxe",
			place: "place_faxe",
			text: "text_faxe",
			descr: "descr_faxe",
			img: ["faxe bianco ns.jpg", "faxe.jpg"],
		},
		{
			title: "Hermes",
			slug: "hermes",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_hermes_usc", "dimast_hermes_tel"],
			dotazione: "dotazione_hermes",
			imballo: "cm. 151 x 78 x 35",
			pesol: "kg. 58",
			campo: "campo_hermes",
			place: "place_hermes",
			text: "text_hermes",
			descr: "descr_hermes",
			img: [
				"Hermes 1.jpg",
				"HermesWhite2.jpg",
				"calcio-balilla-moderno-hermes.jpg",
			],
		},
		{
			title: "Hobby",
			slug: "hobby",
			gettoniera: false,
			dimest: "cm. 146 x 75 x 90",
			dimast: ["dimast__hobby_usc", "dimast__hobby_tel"],
			dotazione: "dotazione__hobby",
			imballo: "cm. 151 x 78 x 35",
			pesol: "kg. 55",
			campo: "campo__hobby",
			place: "place__hobby",
			text: "text__hobby",
			descr: "descr__hobby",
			img: ["hobby red.jpg", "Hobby.jpg", "calcio-balilla-stile-retro.jpg"],
		},
		/*
		{
			title: "Millennium",
			slug: "millennium",
			gettoniera: true,
			dimest: "cm. 159 x 75 x 90",
			dimast: ["dimast_millennium_usc", "dimast_millennium_tel"],
			dotazione: "dotazione_millennium",
			imballo: "cm. 170 x 78 x 44",
			pesol: "kg. 88",
			campo: "campo_millennium",
			place: "place_millennium.",
			text: "text_millennium",
			descr: "descr_millennium",
			img: ["calcio-balilla-millennium.jpg"],
		},
		*/
		{
			title: "Outdoor",
			slug: "outdoor",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_outdoor_usc", "dimast_outdoor_tel"],
			dotazione: "dotazione_outdoor",
			imballo: "cm. 151 x 78 x 35",
			pesol: "kg. 68",
			campo: "campo_outdoor",
			place: "place_outdoor",
			text: "text_outdoor",
			descr: "descr_outdoor",
			img: [
				"Outdoor.png",
				"porta-outdoor.JPG",
				"particolare campo gioco-outd.jpg",
				"particolare outdoor.jpg",
			],
		},
		{
			title: "Phenix",
			slug: "phenix",
			gettoniera: false,
			dimest: "cm. 149 x 76 x 90",
			dotazione: "dotazione_phenix",
			pesol: "kg. 60",
			place: "place_phenix",
			text: "text_phenix",
			descr: "descr_phenix",
			img: [
				"Phenix nero no sfondo.jpg",
				"phenix-rosso-jaipur.jpg",
				"phenix-verde-comodoro.jpg",
				"phenix-blu-fes.jpg",
				"phenix-bianco-alaska.jpg",
			],
		},
		{
			title: "Premier League",
			slug: "premierleague",
			gettoniera: true,
			dimest: "cm. 147 x 75 x 90",
			dimast: ["dimast_premier_usc", "dimast_premier_tel"],
			campo: "campo_premier",
			imballo: "imballo_premier",
			dotazione: "dotazione_premier",
			pesol: "kg. 88",
			place: "place_premier",
			text: "text_premier",
			descr: "descr_premier",
			img: ["premier-league.jpg"],
		},
		{
			title: "title_premierC",
			slug: "premierleaguecoperto",
			gettoniera: true,
			dimest: "cm. 147 x 75 x 90",
			dimast: ["dimast_premierC_usc", "dimast_premierC_tel"],
			campo: "campo_premierC",
			imballo: "imballo_premierC",
			dotazione: "dotazione_premierC",
			pesol: "kg. 88",
			place: "place_premierC",
			text: "text_premierC",
			descr: "descr_premierC",
			img: ["calcio-balilla-premier-league.jpg"],
		},
		{
			title: "Pride",
			slug: "pride",
			gettoniera: true,
			dimest: "cm. 140 x 75 x 90",
			dimast: ["dimast_pride_usc", "dimast_pride_tel"],
			campo: "campo_pride",
			imballo: "cm. 151 x 78 x 40",
			dotazione: "dotazione_pride",
			pesol: "kg. 76",
			place: "place_pride",
			text: "text_pride",
			descr: "descr_pride",
			img: ["pride.png"],
		},
		{
			title: "title_prideC",
			slug: "pridecoperto",
			gettoniera: true,
			dimest: "cm. 140 x 75 x 90",
			dimast: ["dimast_prideC_usc", "dimast_prideC_tel"],
			campo: "campo_prideC",
			imballo: "cm. 151 x 78 x 40",
			dotazione: "dotazione_prideC",
			pesol: "kg. 76",
			place: "place_prideC",
			text: "text_prideC",
			descr: "descr_prideC",
			img: ["pride-coperto.jpg"],
		},
		{
			title: "title_8xl",
			slug: "xl",
			gettoniera: false,
			dimest: "cm. 266 x 75 x 90",
			dimast: ["dimast_8xl_usc", "dimast_8xl_tel"],
			campo: "cm. 240 x 70,2",
			imballo: "cm. 275 x 78 x 35",
			dotazione: "dotazione_8xl",
			pesol: "kg. 150",
			place: "place_8xl",
			text: "text_8xl",
			descr: "descr_8xl",
			img: ["xl.jpg"],
		},
		{
			title: "Sardi Sport",
			slug: "sardisport",
			gettoniera: false,
			dimest: "cm. 144 x 76 x 90",
			dimast: ["dimast_sardisport_usc", "dimast_sardisport_tel"],
			dotazione: "dotazione_sardisport",
			imballo: "cm. 170 x 78 x 44",
			pesol: "kg. 120",
			campo: "campo_sardisport",
			place: "place_sardisport",
			text: "text_sardisport",
			descr: "descr_sardisport",
			img: ["sardisport.png"],
		},
		{
			title: "Speedy",
			slug: "speedy",
			gettoniera: false,
			dimest: "cm. 140 x 76 x 90",
			dimast: ["dimast_speedy_usc", "dimast_speedy_tel"],
			dotazione: "dotazione_speedy",
			imballo: "cm. 151 x 78 x 35",
			pesol: "kg. 60",
			campo: "campo_speedy",
			place: "place_speedy",
			text: "text_speedy",
			descr: "descr_speedy",
			img: ["speedy web.jpg", "speedy 2 testata gialla.jpg", "speedy mog.JPG"],
		},
		{
			title: "Twister",
			slug: "twister",
			gettoniera: false,
			dimest: "cm. 134 x 75 x 90",
			dimast: ["dimast_twister_usc", "dimast_twister_tel"],
			dotazione: "dotazione_twister",
			imballo: "cm. 145 x 76 x 28",
			pesol: "kg. 50",
			campo: "campo_twister",
			place: "place_twister",
			text: "text_twister",
			descr: "descr_twister",
			img: ["twister.jpg"],
		},
		{
			title: "Standard",
			slug: "standard",
			gettoniera: true,
			dimest: "cm. 140 x 75 x 90",
			dimast: ["dimast_pride_usc", "dimast_pride_tel"],
			dotazione: "dotazione_pride",
			imballo: "cm. 151 x 78 x 40",
			pesol: "kg. 76",
			campo: "campo_pride",
			place: "place_pride",
			text: "text_pride",
			descr: "descr_standard",
			img: ["standard.jpg"],
		},
		{
			title: "standardC_title",
			slug: "standardcoperto",
			gettoniera: true,
			dimest: "cm. 140 x 75 x 90",
			dimast: ["dimast_prideC_usc", "dimast_prideC_tel"],
			dotazione: "dotazione_pridCe",
			imballo: "cm. 151 x 78 x 40",
			pesol: "kg. 76",
			campo: "campo_prideC",
			place: "place_standardC",
			text: "text_prideC",
			descr: "descr_standard",
			img: ["Standard-coperto.jpg"],
		},
	],
	Altrigiochi: [
		{
			title: "Shuffleboard9_title",
			slug: "shuffleboard9",
			gettoniera: false,
			dimensioni: "shuff_dim",
			piano: "cm. 243 x 46",
			peson: "kg. 120",
			pesol: "kg. 125",
			place: "shuff9_place",
			text: "shuff9_text",
			img: [
				"Shuff.9lat.jpg",
				"Shuff.9'.jpg",
				"puck.JPG",
				"puck1.JPG",
				"puck2.JPG",
			],
		},
		{
			title: "Shuffleboard12_title",
			slug: "shuffleboard12",
			gettoniera: false,
			dimensioni: "shuff_dim",
			piano: "cm. 243 x 46",
			peson: "kg. 120",
			pesol: "kg. 125",
			place: "shuff12_place",
			text: "shuff12_text",
			img: [
				"Shuff12.jpg",
				"Shuff12lat.jpg",
				"puck.JPG",
				"puck1.JPG",
				"puck2.JPG",
			],
		},
		{
			title: "Ping Pong Orbit",
			slug: "orbit",
			gettoniera: false,
			dimensioni: "orbit_dim",
			imballo: "orbit_imballo",
			piano: "orbit_piano",
			peson: "kg. 54",
			pesol: "kg. 57",
			opzione: "orbit_opt",
			descr:
				"orbit_descr",
			place: "orbit_place",
			text: "orbit_text",
			img: [
				"Orbit.png",
				"Orbit1.png",
				"particolare divisorio.jpg",
				"particolare-divisorio-2.jpg",
				"ping-pong-orbit-dettaglio.jpg",
			],
		},
		{
			title: "Grizzly",
			slug: "grizzly",
			gettoniera: false,
			dimensioni: "cm.232 x 132",
			imballo: "cm. 235 x 135 x 30 + cm. 100 x 90 x 55",
			pesol: "kg. 260",
			dotazione: "grizzly_dot",
			descr:
				"grizzly_descr",
			place: "grizzly_place",
			text: "grizzly_text",
			img: ["air-hockey-table.jpg"],
		},
	],
};
