import React from "react";
import Logo from "../../../Assets/Pictures/logon.png";
import Omino from "../../../Assets/Pictures/omino.png";

function Footer() {
	return (
		<div>
			<img
				src={Omino}
				className="relative left-[50%] translate-x-[-50%] translate-y-[30%] z-20"
				width="12px"
				alt={"omino"}
			></img>
            <div className="h-1 w-full bg-black relative translate-y-[-150%]"></div>
			<div className="m-0 p-12 shadow-md border-t border-solid border-quicksilver bg-quicksilver w-full relative bottom-0">
				<img
					src={Logo}
					alt="logo"
					width={"20%"}
					className="ml-[50%] translate-x-[-50%]"
				></img>
                <div className="block pt-10">
                    <a href="/">SARDI G. & D. snc</a><br/>
                    <a href="/contatti/dovesiamo">Deviazione Statale 10, n. 57 </a> <br/>
                    <a href="/contatti/dovesiamo">15122 Spinetta Marengo (AL)</a> <br/>
                    <a href="/contatti">Tel. +39 0131 618397 </a> <br/>
                    <a href="/contatti">Fax: +39 0131 617249 </a> <br/>
                    <a href="/contatti">Email: info@sarditable.it </a> <br/>
                    <a href="/contatti">P.Iva 00162720064</a> <br/>
                    <a href="/privacy">Privacy Policy </a> <br/>
                    <a href="/privacy">Cookie Policy </a> <br/>
                </div>
			</div>
		</div>
	);
}

export default Footer;
